import { gsap } from "gsap";
import { type RefObject, useEffect } from "react";

type Props<T> = {
    items: Array<T>;
    containerRef: RefObject<HTMLDivElement>;
    itemsRef: RefObject<Array<HTMLDivElement>>;
};

export default function useUpdateTransform<T>({
    items = [],
    containerRef,
    itemsRef
}: Props<T>) {
    useEffect(() => {
        const container = containerRef?.current;
        const items = itemsRef.current;

        if (!container || !items) return;

        const updateTransform = () => {
            const parentRect = container.getBoundingClientRect();
            const parentTop = parentRect.top + window.scrollY;
            const parentHeight = container.offsetHeight;
            const windowHeight = window.innerHeight;
            const scrollTop = window.scrollY || window.pageYOffset;

            const scrollPercent = Math.min(
                Math.max(
                    (scrollTop - parentTop + windowHeight) /
                        (parentHeight + windowHeight),
                    0
                ),
                1
            );

            items.forEach((item, index) => {
                const maxTranslateY = window.matchMedia("(min-width: 1024px)")
                    .matches
                    ? (index + 1) * 140
                    : window.matchMedia("(min-width: 768px)").matches
                      ? (index + 1) * 120
                      : (index + 1) * 60;

                gsap.to(item, {
                    y: -maxTranslateY * (1 - scrollPercent),
                    duration: 0,
                    ease: "power1.out"
                });
            });
        };

        updateTransform();

        window.addEventListener("scroll", updateTransform);

        window.addEventListener("resize", updateTransform);

        return () => {
            window.removeEventListener("scroll", updateTransform);
            window.removeEventListener("resize", updateTransform);
        };
    }, [items]);
}
